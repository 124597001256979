<!-- <mat-progress-bar mode="indeterminate" ></mat-progress-bar> -->
<div class="loader-container"
  *ngIf="loader$ | async">
  <div class="lds-grid">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
