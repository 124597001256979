<section id="not-found" class="wow fadeIn">
  <div class="container text-center mt-5">
    <div class="col-4 mx-auto">
      <img
        src="assets/imgs/404.png"
        alt="404 Not Found"
        class="w-100 wow bounceIn"
      />
      <button
        class="btn back-btn w-100 font-weight-bold mt-5"
        (click)="routeToHome()"
      >
        Back to Dashboard
      </button>
    </div>
  </div>
</section>
